var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.qs && !_vm.loading ? _c('div', [_c('Row', {
    staticStyle: {
      "margin-top": "24px"
    },
    attrs: {
      "gutter": 24,
      "type": "flex"
    }
  }, [_c('br'), _vm.qs ? _c('Col', {
    attrs: {
      "span": "12"
    }
  }, [_c('ApiWidgetFactory', {
    attrs: {
      "namespace": "cookie",
      "special-call": "getComplianceIndexDailies",
      "description": {
        title: _vm.$t('global.compliance_index.daily_value'),
        desc: _vm.$t('global.more_details')
      },
      "params": {
        properties: [this.scopeSelectedProperty.id],
        period: this.scopeSelectedDaterange,
        direction: 'asc',
        sort: 'ComplianceindexDailies.date',
        limit: 180
      },
      "type": "LINE"
    }
  })], 1) : _vm._e(), _vm.qs ? _c('Col', {
    attrs: {
      "span": "12"
    }
  }, [_c('Row', {
    staticStyle: {
      "flex-direction": "column"
    },
    attrs: {
      "type": "flex"
    }
  }, [_c('h1', {
    staticStyle: {
      "margin-bottom": "24px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('global.last_data')) + " : " + _vm._s(_vm.complianceData ? _vm.moment(_vm.lastComplianceData[0].date).format('LL') : '...') + " ")]), _c('ApiWidgetFactory', {
    attrs: {
      "namespace": "cookie",
      "special-call": "getComplianceIndexDailies",
      "description": {
        title: _vm.$t('global.cookie.compliance_index'),
        desc: _vm.$t('global.cookie.compliance_index_desc')
      },
      "params": {
        properties: [this.scopeSelectedProperty.id],
        limit: 1,
        direction: 'desc',
        sort: 'ComplianceindexDailies.date'
      },
      "type": "RISK"
    }
  }), _vm.qs ? _c('Card', {
    staticStyle: {
      "margin-top": "24px"
    }
  }, [_c('span', {
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_vm._v(" " + _vm._s(_vm.$t('global.compliance_index.value')) + " ")]), _vm.complianceData ? _c('div', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_c('div', {
    staticClass: "big-number big-number--action"
  }, [_vm._v(" " + _vm._s(_vm.lastComplianceData[0].compliancevalue) + " ")]), _c('br'), _c('Button', {
    attrs: {
      "type": "primary",
      "size": "large"
    },
    on: {
      "click": _vm.openLastComplianceDetail
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('global.compliance_index.details')) + " ")])], 1) : _c('Spin', {
    attrs: {
      "fix": ""
    }
  }, [_c('Icon', {
    staticClass: "demo-spin-icon-load",
    attrs: {
      "type": "ios-loading",
      "size": "18"
    }
  }), _c('div', [_vm._v(_vm._s(_vm.$t('global.loading')))])], 1)], 1) : _vm._e()], 1)], 1) : _vm._e()], 1), _c('Row', {
    staticStyle: {
      "margin-top": "24px"
    }
  }, [_c('Detail', {
    attrs: {
      "compliance-index-data": _vm.detailData,
      "thresold-values": _vm.thresoldValues,
      "open": _vm.openDetail
    },
    on: {
      "close": function close($event) {
        _vm.openDetail = false;
      }
    }
  }), _c('Col', {
    attrs: {
      "span": "24"
    }
  }, [_c('Card', [_c('p', {
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_vm._v(" " + _vm._s(_vm.$t('global.compliance_index.rules')) + " ")]), _c('div', [_c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t('global.compliance_index.value_desc'))
    }
  }), _c('br'), _c('strong', [_vm._v(_vm._s(_vm.$t('global.compliance_index.score_desc')) + " : ")]), _c('br'), _c('br'), _c('List', {
    attrs: {
      "size": "small",
      "split": false
    }
  }, [_c('ListItem', [_vm._v(" " + _vm._s(_vm.$t('global.compliance_index.index')) + " = "), _c('Tag', {
    staticStyle: {
      "margin": "0 5px"
    },
    attrs: {
      "color": _vm.riskColor['A']
    }
  }, [_vm._v(" A ")]), _vm._v(" " + _vm._s(_vm.$t('global.compliance_index.if_value_below')) + " "), _c('strong', {
    staticStyle: {
      "margin": "0 5px"
    }
  }, [_vm._v(_vm._s(_vm.thresoldValues[0] && _vm.thresoldValues[0].value))])], 1), _c('ListItem', [_vm._v(" " + _vm._s(_vm.$t('global.compliance_index.index')) + " = "), _c('Tag', {
    staticStyle: {
      "margin": "0 5px"
    },
    attrs: {
      "color": _vm.riskColor['B']
    }
  }, [_vm._v(" B ")]), _vm._v(" " + _vm._s(_vm.$t('global.compliance_index.if_value_below')) + " "), _c('strong', {
    staticStyle: {
      "margin": "0 5px"
    }
  }, [_vm._v(_vm._s(_vm.thresoldValues[1] && _vm.thresoldValues[1].value))])], 1), _c('ListItem', [_vm._v(" " + _vm._s(_vm.$t('global.compliance_index.index')) + " = "), _c('Tag', {
    staticStyle: {
      "margin": "0 5px"
    },
    attrs: {
      "color": _vm.riskColor['C']
    }
  }, [_vm._v(" C ")]), _vm._v(" " + _vm._s(_vm.$t('global.compliance_index.if_value_below')) + " "), _c('strong', {
    staticStyle: {
      "margin": "0 5px"
    }
  }, [_vm._v(_vm._s(_vm.thresoldValues[2] && _vm.thresoldValues[2].value))])], 1), _c('ListItem', [_vm._v(" " + _vm._s(_vm.$t('global.compliance_index.index')) + " = "), _c('Tag', {
    staticStyle: {
      "margin": "0 5px"
    },
    attrs: {
      "color": _vm.riskColor['D']
    }
  }, [_vm._v(" D ")]), _vm._v(" " + _vm._s(_vm.$t('global.compliance_index.if_value_below')) + " "), _c('strong', {
    staticStyle: {
      "margin": "0 5px"
    }
  }, [_vm._v(_vm._s(_vm.thresoldValues[3] && _vm.thresoldValues[3].value))])], 1), _c('ListItem', [_vm._v(" " + _vm._s(_vm.$t('global.compliance_index.index')) + " = "), _c('Tag', {
    staticStyle: {
      "margin": "0 5px"
    },
    attrs: {
      "color": _vm.riskColor['E']
    }
  }, [_vm._v(" E ")]), _vm._v(_vm._s(_vm.$t('global.compliance_index.if_value_above')) + " "), _c('strong', [_vm._v("   " + _vm._s(_vm.thresoldValues[3] && _vm.thresoldValues[3].value))])], 1)], 1), _c('Divider'), _c('p', [_c('strong', [_vm._v("The " + _vm._s(_vm.$t('global.compliance_index.index')) + " " + _vm._s(_vm.$t('global.compliance_index.value_built_on')) + " ")])]), _c('br'), _c('List', {
    attrs: {
      "split": false,
      "size": "small"
    }
  }, [_c('ListItem', [_vm._v(" - "), _c('strong', {
    staticStyle: {
      "margin": "0 5px"
    }
  }, [_vm._v("[C1a] ")]), _vm._v(" " + _vm._s(_vm.$t('global.compliance_index.r1')) + " ")]), _c('ListItem', [_vm._v(" - "), _c('strong', {
    staticStyle: {
      "margin": "0 5px"
    }
  }, [_vm._v("[C1b] ")]), _vm._v(" " + _vm._s(_vm.$t('global.compliance_index.r2')) + " ")]), _c('ListItem', [_vm._v(" - "), _c('strong', {
    staticStyle: {
      "margin": "0 5px"
    }
  }, [_vm._v("[C2a] ")]), _vm._v(" " + _vm._s(_vm.$t('global.compliance_index.r3')) + " ")]), _c('ListItem', [_vm._v(" - "), _c('strong', {
    staticStyle: {
      "margin": "0 5px"
    }
  }, [_vm._v("[C2b] ")]), _vm._v(" " + _vm._s(_vm.$t('global.compliance_index.r4')) + " ")]), _c('ListItem', [_vm._v(" - "), _c('strong', {
    staticStyle: {
      "margin": "0 5px"
    }
  }, [_vm._v("[C3] ")]), _vm._v(" " + _vm._s(_vm.$t('global.compliance_index.r5')) + " ")])], 1), _c('Divider'), _c('p', [_vm._v(" " + _vm._s(_vm.$t('global.compliance_index.nb')) + " "), _c('Tag', [_vm._v("1")])], 1), _c('br'), _c('p', [_vm._v(" " + _vm._s(_vm.$t('global.compliance_index.value')) + " = ( "), _c('Tag', [_vm._v("2")]), _vm._v(" x C1a) + ( "), _c('Tag', [_vm._v("4")]), _vm._v(" x C1b) + ( "), _c('Tag', [_vm._v("3")]), _vm._v(" x C2a) + ( "), _c('Tag', [_vm._v("5")]), _vm._v(" x C2b) + ( "), _c('Tag', [_vm._v("1")]), _vm._v(" x C3)")], 1)], 1)])], 1)], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }