var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !_vm.loading ? _c('Drawer', {
    attrs: {
      "width": "80vw",
      "title": "Compliance index details for the ".concat(_vm.moment(_vm.complianceIndexData[0].date).format('LL'))
    },
    on: {
      "on-close": function onClose($event) {
        return _vm.$emit('close');
      }
    },
    model: {
      value: _vm.openDrawer,
      callback: function callback($$v) {
        _vm.openDrawer = $$v;
      },
      expression: "openDrawer"
    }
  }, [!_vm.loading ? _c('div', [_c('p', [_c('strong', [_vm._v("Vendors and trackers found which are impacting the compliance index value")])]), _c('br'), _c('br'), _c('h3', [_c('strong', [_vm._v("Compliance index value :")]), _c('Tag', {
    staticStyle: {
      "margin": "0 5px"
    },
    attrs: {
      "color": _vm.alphaRiskColor[_vm.complianceIndexData[0].complianceindex]
    }
  }, [_c('span', {
    style: {
      color: _vm.riskColor[_vm.complianceIndexData[0].complianceindex],
      fontWeight: 'bold'
    }
  }, [_vm._v(" " + _vm._s(_vm.complianceIndexData[0].compliancevalue) + " ")])])], 1), _c('h3', [_c('strong', [_vm._v("Compliance index :")]), _c('Tag', {
    staticStyle: {
      "margin": "0 5px"
    },
    attrs: {
      "color": _vm.alphaRiskColor[_vm.complianceIndexData[0].complianceindex]
    }
  }, [_c('span', {
    style: {
      color: _vm.riskColor[_vm.complianceIndexData[0].complianceindex],
      fontWeight: 'bold'
    }
  }, [_vm._v(" " + _vm._s(_vm.complianceIndexData[0].complianceindex) + " ")])])], 1), _c('br'), _c('List', {
    attrs: {
      "size": "small",
      "split": false
    }
  }, [_c('ListItem', [_vm._v(" Compliance Index = "), _c('Tag', {
    staticStyle: {
      "margin": "0 5px"
    },
    attrs: {
      "color": _vm.riskColor['A']
    }
  }, [_vm._v(" A ")]), _vm._v(" if Compliance value < "), _c('strong', {
    staticStyle: {
      "margin": "0 5px"
    }
  }, [_vm._v(_vm._s(_vm.thresoldValues[0] && _vm.thresoldValues[0].value))])], 1), _c('ListItem', [_vm._v(" Compliance Index = "), _c('Tag', {
    staticStyle: {
      "margin": "0 5px"
    },
    attrs: {
      "color": _vm.riskColor['B']
    }
  }, [_vm._v(" B ")]), _vm._v(" if Compliance value < "), _c('strong', {
    staticStyle: {
      "margin": "0 5px"
    }
  }, [_vm._v(_vm._s(_vm.thresoldValues[1] && _vm.thresoldValues[1].value))])], 1), _c('ListItem', [_vm._v(" Compliance Index = "), _c('Tag', {
    staticStyle: {
      "margin": "0 5px"
    },
    attrs: {
      "color": _vm.riskColor['C']
    }
  }, [_vm._v(" C ")]), _vm._v(" if Compliance value < "), _c('strong', {
    staticStyle: {
      "margin": "0 5px"
    }
  }, [_vm._v(_vm._s(_vm.thresoldValues[2] && _vm.thresoldValues[2].value))])], 1), _c('ListItem', [_vm._v(" Compliance Index = "), _c('Tag', {
    staticStyle: {
      "margin": "0 5px"
    },
    attrs: {
      "color": _vm.riskColor['D']
    }
  }, [_vm._v(" D ")]), _vm._v(" if Compliance value < "), _c('strong', {
    staticStyle: {
      "margin": "0 5px"
    }
  }, [_vm._v(_vm._s(_vm.thresoldValues[3] && _vm.thresoldValues[3].value))])], 1), _c('ListItem', [_vm._v(" Compliance Index = "), _c('Tag', {
    staticStyle: {
      "margin": "0 5px"
    },
    attrs: {
      "color": _vm.riskColor['E']
    }
  }, [_vm._v(" E ")]), _vm._v(" if Compliance value is above "), _c('strong', {
    staticStyle: {
      "margin": "0 5px"
    }
  }, [_vm._v(_vm._s(_vm.thresoldValues[3] && _vm.thresoldValues[3].value))])], 1)], 1), _c('br'), _c('Collapse', {
    model: {
      value: _vm.value1,
      callback: function callback($$v) {
        _vm.value1 = $$v;
      },
      expression: "value1"
    }
  }, [_c('Panel', {
    attrs: {
      "name": "1"
    }
  }, [_vm._v(" Actives vendors (" + _vm._s(_vm.data['ACTIVE_VENDORS'].length) + ") "), _c('Table', {
    attrs: {
      "slot": "content",
      "stripe": "",
      "data": _vm.data['ACTIVE_VENDORS'],
      "columns": _vm.activeVendorsCols
    },
    slot: "content"
  })], 1), _c('Panel', {
    attrs: {
      "name": "2"
    }
  }, [_vm._v(" First party trackers " + _vm._s(this.$t('global.scenarios.without_choice')) + " (" + _vm._s(_vm.data['BEFORE_CONSENT']['FIRST_PARTY'].length) + ") "), _c('Table', {
    attrs: {
      "slot": "content",
      "stripe": "",
      "data": _vm.data['BEFORE_CONSENT']['FIRST_PARTY'],
      "columns": _vm.trackersCols1st
    },
    slot: "content"
  })], 1), _c('Panel', {
    attrs: {
      "name": "3"
    }
  }, [_vm._v(" Third party trackers " + _vm._s(this.$t('global.scenarios.without_choice')) + " (" + _vm._s(_vm.data['BEFORE_CONSENT']['THIRD_PARTY'].length) + ") "), _c('Table', {
    attrs: {
      "slot": "content",
      "stripe": "",
      "data": _vm.data['BEFORE_CONSENT']['THIRD_PARTY'],
      "columns": _vm.trackersCols1st
    },
    slot: "content"
  })], 1), _c('Panel', {
    attrs: {
      "name": "4"
    }
  }, [_vm._v(" First party trackers " + _vm._s(this.$t('global.scenarios.refusal_choice')) + " (" + _vm._s(_vm.data['DESPITE_REFUSAL']['FIRST_PARTY'].length) + ") "), _c('Table', {
    attrs: {
      "slot": "content",
      "stripe": "",
      "data": _vm.data['DESPITE_REFUSAL']['FIRST_PARTY'],
      "columns": _vm.trackersCols1st
    },
    slot: "content"
  })], 1), _c('Panel', {
    attrs: {
      "name": "5"
    }
  }, [_vm._v(" Third party trackers " + _vm._s(this.$t('global.scenarios.refusal_choice')) + " (" + _vm._s(_vm.data['DESPITE_REFUSAL']['THIRD_PARTY'].length) + ") "), _c('Table', {
    attrs: {
      "slot": "content",
      "stripe": "",
      "data": _vm.data['DESPITE_REFUSAL']['THIRD_PARTY'],
      "columns": _vm.trackersCols1st
    },
    slot: "content"
  })], 1)], 1), _c('br'), _c('Button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.downloadEachCsv
    }
  }, [_vm._v(" Download data as CSV ")])], 1) : _vm._e()]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }