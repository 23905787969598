<template>
  <Drawer v-model="openDrawer"
          v-if="!loading"
          width="80vw"
          @on-close="$emit('close')"
          :title="`Compliance index details for the ${moment(complianceIndexData[0].date).format('LL')}`"
  >
    <div v-if="!loading">
      <p><strong>Vendors and trackers found which are impacting the compliance index value</strong></p>
      <br>
      <br>
      <h3>
        <strong>Compliance index value :</strong>
        <Tag :color="alphaRiskColor[complianceIndexData[0].complianceindex]"
             style="margin:0 5px"
        >
          <span :style="{color: riskColor[complianceIndexData[0].complianceindex], fontWeight:'bold'}">
            {{ complianceIndexData[0].compliancevalue }}
          </span>
        </Tag>
      </h3>
      <h3>
        <strong>Compliance index :</strong>
        <Tag :color="alphaRiskColor[complianceIndexData[0].complianceindex]"
             style="margin:0 5px"
        >
          <span :style="{color: riskColor[complianceIndexData[0].complianceindex], fontWeight:'bold'}">
            {{ complianceIndexData[0].complianceindex }}
          </span>
        </Tag>
      </h3>
      <br>
      <List size="small"
            :split="false"
      >
        <ListItem>
          Compliance Index =   <Tag style="margin:0 5px"
                                    :color="riskColor['A']"
          >
            A
          </Tag>   if Compliance value &#60; <strong style="margin:0 5px">{{ thresoldValues[0] && thresoldValues[0].value }}</strong>
        </ListItem>
        <ListItem>
          Compliance Index =   <Tag style="margin:0 5px"
                                    :color="riskColor['B']"
          >
            B
          </Tag>   if Compliance value &#60; <strong style="margin:0 5px">{{ thresoldValues[1] &&thresoldValues[1].value }}</strong>
        </ListItem>
        <ListItem>
          Compliance Index =   <Tag style="margin:0 5px"
                                    :color="riskColor['C']"
          >
            C
          </Tag>   if Compliance value &#60; <strong style="margin:0 5px">{{ thresoldValues[2] && thresoldValues[2].value }}</strong>
        </ListItem>
        <ListItem>
          Compliance Index =   <Tag style="margin:0 5px"
                                    :color="riskColor['D']"
          >
            D
          </Tag>   if Compliance value &#60; <strong style="margin:0 5px">{{ thresoldValues[3] && thresoldValues[3].value }}</strong>
        </ListItem>
        <ListItem>
          Compliance Index =   <Tag style="margin:0 5px"
                                    :color="riskColor['E']"
          >
            E
          </Tag> if Compliance value is above <strong style="margin:0 5px">{{ thresoldValues[3] && thresoldValues[3].value }}</strong>
        </ListItem>
      </List>
      <br>
      <Collapse v-model="value1">
        <Panel name="1">
          Actives vendors ({{ data['ACTIVE_VENDORS'].length }})
          <Table slot="content"
                 stripe
                 :data="data['ACTIVE_VENDORS']"
                 :columns="activeVendorsCols"
          />
        </Panel>
        <Panel name="2">
          First party trackers {{ this.$t('global.scenarios.without_choice') }} ({{ data['BEFORE_CONSENT']['FIRST_PARTY'].length }})
          <Table slot="content"
                 stripe

                 :data="data['BEFORE_CONSENT']['FIRST_PARTY']"
                 :columns="trackersCols1st"
          />
        </Panel>
        <Panel name="3">
          Third party trackers {{ this.$t('global.scenarios.without_choice') }} ({{ data['BEFORE_CONSENT']['THIRD_PARTY'].length }})
          <Table slot="content"
                 stripe

                 :data="data['BEFORE_CONSENT']['THIRD_PARTY']"
                 :columns="trackersCols1st"
          />
        </Panel>
        <Panel name="4">
          First party trackers {{ this.$t('global.scenarios.refusal_choice') }} ({{ data['DESPITE_REFUSAL']['FIRST_PARTY'].length }})
          <Table slot="content"
                 stripe

                 :data="data['DESPITE_REFUSAL']['FIRST_PARTY']"
                 :columns="trackersCols1st"
          />
        </Panel>
        <Panel name="5">
          Third party trackers {{ this.$t('global.scenarios.refusal_choice') }} ({{ data['DESPITE_REFUSAL']['THIRD_PARTY'].length }})
          <Table slot="content"
                 stripe

                 :data="data['DESPITE_REFUSAL']['THIRD_PARTY']"
                 :columns="trackersCols1st"
          />
        </Panel>
      </Collapse>
      <br>
      <Button type="primary"
              @click="downloadEachCsv"
      >
        Download data as CSV
      </Button>
    </div>
  </Drawer>
</template>
<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
export default {
  name: 'ComplianceIndexDetails',
  data: function () {
    return {
      data: null,
      moment: moment,
      openDrawer: false,
      loading: true,
      value1: null,
      activeVendorsCols: [
        {sortable:true,key: 'vendorName', title: 'Vendor', 
          render: (h, params) => {
            return h('router-link', {props: {to: `/vendors/${params.row.vendorUuid}`}}, params.row.vendorName)
          }
        },
        {
          title: 'Category',
          key: 'vendorcategoryName'
        },
        {
          title: 'Country ',
          key: 'countryName'
        },
        {
          title: 'iab TCF',
          key: 'vendorIABv2'
        },
        {
          title: 'Ads.txt',
          render: (h, params) => h('span', {}, params.row.vendorIsInAdsTxt ? 'In' : 'Out')
        },
        {
          title: 'CMP',
          render: (h, params) => h('span', {}, params.row.vendorIsInCMP ? 'In' : 'Out')
        },
        {
          title: 'Gatp',
          render: (h, params) => h('span', {}, params.row.vendorIsInGATP ? 'In' : 'Out')
        },
        {sortable:true,key: 'vendorVigilance', title: 'Vendor vigilance', render: (h, params) => {
          const color = this.riskColor[this.vendorVigilanceToLetter(params.row.vendorVigilance * 5)]
          return h('Tag', {
            props: {
              color: color,
              type: 'dot'
            }
            
          }, this.vendorVigilanceToLetter(params.row.vendorVigilance * 5))
        }}
      ],
      trackersCols1st: [
        {sortable:true, key: 'trackerName', title: 'Name'},
        {sortable:true, key:'trackerHost', title:'Domain'},
        {sortable:true, title: 'Vendor',  render: (h, params) => {
          return h('router-link', {props: {to: `/vendors/${params.row.vendorUuid}`}}, params.row.vendorName)
        }},
        {sortable:true, key: 'vendorcategoryName', title: 'Category'},
        {sortable:true, key: 'trackerType', title: 'Type'},
        {sortable:true, title: 'Persistence', render:(h,params) => h('span',{}, params.row.trackerIsPersistent ? 'Yes' : 'No')},
        {title: 'Lifetime', render: (h, params) => h('span', {}, this.getCookieAgeInMonthOrDays(params.row.trackerLifeTime))},
      ]
    }
    
  },
  watch: {
    complianceIndexData: {
      immediate: true,
      deep: true,
      handler: function (val, old) {
        if (val && val.length) {
          this.data = JSON.parse(val[0].detail)
          this.$nextTick(()=> {
            this.loading = false
          })
        }
      }
    },
    open: {
      handler: function (val, old) {
        this.openDrawer = val
      }
    }
  },
  props: {
    complianceIndexData: {
      type: Array,
      note: 'the compliance object',
      default : () => []
    },
    thresoldValues: {
      type: Array,
      note: 'compliance index values',
      default: () => [0,0,0,0]
    },
    open: {
      type: Boolean,
      note: 'if The drawer is open',
      default: false
    }
  },
  computed: {
    ...mapGetters({
      daterange: 'scope/selectedDaterange',
      property: 'scope/selectedProperty',
      vendorVigilanceToLetter: 'vendor/vendorVigilanceToLetter',
      riskColor: 'vendor/riskColor',
      alphaRiskColor: 'vendor/alphaRiskColor'
    })
  },
  methods: {
    downloadEachCsv () {
      this.convertToCsv(this.data['ACTIVE_VENDORS'], 'ActiveVendors-'+this.property.displayName+'--'+this.complianceIndexData[0].date)
      this.convertToCsv(this.data['BEFORE_CONSENT']['FIRST_PARTY'],'Trackers1stParty_dropBeforeConsent-'+this.property.displayName+'--'+this.complianceIndexData[0].date)
      this.convertToCsv(this.data['BEFORE_CONSENT']['THIRD_PARTY'],'Trackers3rdParty_dropBeforeConsent-'+this.property.displayName+'--'+this.complianceIndexData[0].date)
      this.convertToCsv(this.data['DESPITE_REFUSAL']['FIRST_PARTY'],'ActiveTrackers1stParty_dropDespiteRefusal-'+this.property.displayName+'--'+this.complianceIndexData[0].date)
      this.convertToCsv(this.data['DESPITE_REFUSAL']['THIRD_PARTY'],'Trackers3rdParty_dropDespiteRefusal-'+this.property.displayName+'--'+this.complianceIndexData[0].date)
    },
    getCookieAgeInMonthOrDays (days) {
      days = parseInt(days)
      if (typeof days !== 'number') return 'N/A'
      if (days > -31 && days < 31) return days + ' ' + this.$t('global.time.days')
      const month = Math.round(days / 30.5)
      return month + ' ' + this.$t('global.time.months')
    },
    convertToCsv (items, name) {
      const replacer = (key, value) => value === null ? '' : value // specify how you want to handle null values here
      const header = Object.keys(items[0])
      const csv = [
        header.join(','), // header row first
        ...items.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','))
      ].join('\r\n')
      var exportedFilenmae = name + '.csv' || 'export.csv'
      var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
      if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, exportedFilenmae)
      } else {
        var link = document.createElement("a")
        if (link.download !== undefined) { // feature detection
          // Browsers that support HTML5 download attribute
          var url = URL.createObjectURL(blob)
          link.setAttribute("href", url)
          link.setAttribute("download", exportedFilenmae)
          link.style.visibility = 'hidden'
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        }
      }
    }
  }
}
</script>
