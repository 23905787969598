<template>
  <div v-if="qs && !loading">
    <Row :gutter="24"
         type="flex"
         style="margin-top:24px;"
    >
      <br>
      <Col span="12"
           v-if="qs"
      >
        <ApiWidgetFactory
          namespace="cookie"
          special-call="getComplianceIndexDailies"
          :description="{title: $t('global.compliance_index.daily_value'), desc: $t('global.more_details')}"
          :params="{properties: [this.scopeSelectedProperty.id],
                    period: this.scopeSelectedDaterange,direction: 'asc', sort: 'ComplianceindexDailies.date', limit: 180}"
          type="LINE"
        />
      </Col>
      <Col v-if="qs"
           span="12"
      >
        <Row type="flex"
             style="flex-direction:column"
        >
          <h1 style="margin-bottom:24px">
            {{ $t('global.last_data') }} : {{ complianceData ? moment(lastComplianceData[0].date).format('LL') : '...' }}
          </h1>
          <ApiWidgetFactory
            namespace="cookie"
            special-call="getComplianceIndexDailies"
            :description="{title: $t('global.cookie.compliance_index'), desc: $t('global.cookie.compliance_index_desc')}"
            :params="{properties: [this.scopeSelectedProperty.id],limit: 1, direction: 'desc', sort: 'ComplianceindexDailies.date'}"
            type="RISK"
          />
          <Card style="margin-top:24px"
                v-if="qs"
          >
            <span slot="title">
              {{ $t('global.compliance_index.value') }}
            </span>
            <div v-if="complianceData"
                 style="text-align:center;"
            >
              <div
                class="big-number big-number--action"
              >
                {{ lastComplianceData[0].compliancevalue }}
              </div>
              <br>
              <Button type="primary"
                      size="large"
                      @click="openLastComplianceDetail"
              >
                {{ $t('global.compliance_index.details') }}
              </Button>
            </div>
            <Spin fix
                  v-else
            >
              <Icon
                type="ios-loading"
                size="18"
                class="demo-spin-icon-load"
              />
              <div>{{ $t('global.loading') }}</div>
            </Spin>
          </Card>
        </Row>
      </Col>
    </Row>
    <Row style="margin-top:24px;">
      <Detail :compliance-index-data="detailData"
              :thresold-values="thresoldValues"
              :open="openDetail"
              @close="openDetail = false"
      />
      <Col span="24">
        <Card>
          <p slot="title">
            {{ $t('global.compliance_index.rules') }}
          </p>
          <div>
            <p v-html="$t('global.compliance_index.value_desc')" />
            <br>
            <strong>{{ $t('global.compliance_index.score_desc') }} : </strong>
            <br>
            <br>
            <List size="small"
                  :split="false"
            >
              <ListItem>
                {{ $t('global.compliance_index.index') }} =   <Tag style="margin:0 5px"
                                                                   :color="riskColor['A']"
                >
                  A
                </Tag>   {{ $t('global.compliance_index.if_value_below') }} <strong style="margin:0 5px">{{ thresoldValues[0] && thresoldValues[0].value }}</strong>
              </ListItem>
              <ListItem>
                {{ $t('global.compliance_index.index') }} =   <Tag style="margin:0 5px"
                                                                   :color="riskColor['B']"
                >
                  B
                </Tag>   {{ $t('global.compliance_index.if_value_below') }} <strong style="margin:0 5px">{{ thresoldValues[1] && thresoldValues[1].value }}</strong>
              </ListItem>
              <ListItem>
                {{ $t('global.compliance_index.index') }} =   <Tag style="margin:0 5px"
                                                                   :color="riskColor['C']"
                >
                  C
                </Tag>   {{ $t('global.compliance_index.if_value_below') }} <strong style="margin:0 5px">{{ thresoldValues[2] && thresoldValues[2].value }}</strong>
              </ListItem>
              <ListItem>
                {{ $t('global.compliance_index.index') }} =   <Tag style="margin:0 5px"
                                                                   :color="riskColor['D']"
                >
                  D
                </Tag>   {{ $t('global.compliance_index.if_value_below') }} <strong style="margin:0 5px">{{ thresoldValues[3] && thresoldValues[3].value }}</strong>
              </ListItem>
              <ListItem>
                {{ $t('global.compliance_index.index') }} =   <Tag style="margin:0 5px"
                                                                   :color="riskColor['E']"
                >
                  E
                </Tag>{{ $t('global.compliance_index.if_value_above') }} <strong> &nbsp; {{ thresoldValues[3] && thresoldValues[3].value }}</strong>
              </ListItem>
            </List>
            <Divider />
            <p> <strong>The {{ $t('global.compliance_index.index') }} {{ $t('global.compliance_index.value_built_on') }} </strong></p>
            <br>
            <List :split="false"
                  size="small"
            >
              <ListItem>
                - <strong style="margin:0 5px">[C1a] </strong>  {{ $t('global.compliance_index.r1') }}
              </ListItem>
              <ListItem>
                - <strong style="margin:0 5px">[C1b] </strong> {{ $t('global.compliance_index.r2') }}
              </ListItem>

              <ListItem>
                - <strong style="margin:0 5px">[C2a] </strong> {{ $t('global.compliance_index.r3') }}
              </ListItem>

              <ListItem>
                - <strong style="margin:0 5px">[C2b] </strong>  {{ $t('global.compliance_index.r4') }}
              </ListItem>
              <ListItem>
                - <strong style="margin:0 5px">[C3] </strong>   {{ $t('global.compliance_index.r5') }}
              </ListItem>
            </List>
            <Divider />
            <p>  {{ $t('global.compliance_index.nb') }}  <Tag>1</Tag>  </p>
            <br>
            <p> {{ $t('global.compliance_index.value') }} = (  <Tag>2</Tag>   x C1a) + ( <Tag>4</Tag>    x C1b) + (  <Tag>3</Tag>    x C2a) + ( <Tag>5</Tag>    x C2b) + ( <Tag>1</Tag>    x C3)</p>
            <!-- <strong> Exemption schema : </strong>
            <figure>
              <img src="@/assets/images/tracker-exemption.jpg"
                   style="width:100%"
              >
            </figure> -->
          </div>
        </Card>
      </Col>
    </Row>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import ApiWidgetFactory from '@/components/ui/ApiWidgetFactory'
import Detail from '@/views/ComplianceIndex/Detail'
import moment from 'moment'
import {eventBus} from '@/main'

export default {
  name: 'ComplianceIndexView',
  components: {
    ApiWidgetFactory,
    Detail
  },
  data () {
    return {
      complianceData: null,
      loading: true,
      lastComplianceData: null,
      detailData: null,
      moment: moment,
      thresoldValues: [],
      openDetail: false,
    }
  },
  watch: {
    qs: {
      handler: function (val, old) {
        this.fetchData()
        return this.lastComplianceIndex()
      }
    }
  },
  mounted () {
    this.qs && this.fetchData()
    this.qs && this.lastComplianceIndex()
    this.getComplianceindexParams()
    eventBus.$on('open-detail-from-chart', data => {
      this.detailData = [data]
      this.openDetail = true
    })
  },
  computed: {
    ...mapGetters({
      vendorVigilanceToLetter: 'vendor/vendorVigilanceToLetter',
      riskColor: 'vendor/riskColor',
      qs: 'scope/qs',
      scopeSelectedDaterange: 'scope/selectedDaterange',
      scopeSelectedProperty: 'scope/selectedProperty',
    })
  },
  methods: {
    ...mapActions({
      getComplianceData: 'cookie/getComplianceIndexDailies',
      getComplianceScoreDesc: 'cookie/getComplianceScoreDesc'
    }),
    openLastComplianceDetail () {
      this.detailData = this.lastComplianceData
      this.openDetail = true
    },
    lastComplianceIndex () {
      const params = {
        properties: [this.scopeSelectedProperty.id],
        limit: 1,
        direction: 'desc',
        sort: 'ComplianceindexDailies.date'
      }
      return this.getComplianceData({params}).then(r => {
        this.lastComplianceData = r.data
        this.detailData = this.lastComplianceData
      })
    },
    getComplianceindexParams () {
      return this.getComplianceScoreDesc().then(r => {
        this.thresoldValues = r.data
        this.loading = false
      })
    },
    fetchData () {
      const params = {
        properties: [this.scopeSelectedProperty.id],
        period: this.scopeSelectedDaterange,
        sort: 'ComplianceindexDailies.date',
        direction: 'asc'
      }
      return this.getComplianceData({params}).then(r => { this.complianceData = r.data})
    }
  }
}
</script>
<style scope lang="scss">
.big-number {
  font-weight: 100;
  font-size: 80px;
  word-break: break-word;
  text-align: center;
  color: #8A9190;
  display: block;
  line-height: 1;

  &.big-number--action {
    color: #1CA08D;
  }
}
</style>
